.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 100vh;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.message-block {
    flex-grow: 1;
    padding: 16px;
    background-color: rgba(38, 188, 0, 0.5);
    overflow-y: auto;
}

.input-block {
    display: flex;
    align-items: center;
    padding: 16px;
    height: 50px;
    background-color: #f2f2f2;
}
